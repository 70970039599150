//library
import { AppState } from '@components/store/store';
import { Question } from '@components/lib/interfaces/model/models/Question';

/**
 * This selector is used to get current question state
 *
 * @param {AppState} state - The current state
 * @returns {Question} - The question state
 */
const selectedQuestion = (state: AppState): Question => state.simulator.question;

export default selectedQuestion;
