//library
import { AppState } from '@components/store/store';

/**
 * This selector is used to set type of device used by user
 *
 * @param {AppState} state - The current state
 * @returns {'desktop' | 'mobile'} - The direction state
 */
const selectedSet = (state: AppState): 'desktop' | 'mobile' => state.simulator.set;

export default selectedSet;
