//type
import { POPUP } from '@components/store/interfaces/slices/simulator/state';

//library
import { AppState } from '@components/store/store';

/**
 * This selector is used to get current popup state
 *
 * @param {AppState} state - The current state
 * @returns {Record<POPUP, boolean>} - The popup state
 */
const selectedPopup = (state: AppState): Record<POPUP, boolean> => state.simulator.popup;

export default selectedPopup;
