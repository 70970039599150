//type
import { PopUpProps } from '@components/lib/interfaces/template/layout/PopUp';

//library
import React, { ReactElement } from 'react';

/**
 * The pop-up module.
 *
 * @param {PopUpProps} props - The props
 * @returns {React.ReactElement} - The pop-up.
 */
const PopUp: React.FC<PopUpProps> = ({
    data: { open, onClose, width, margin, backgroundColor, padding, rounded },
    children,
    lib: {
        external: { Popup }
    }
}: PopUpProps): ReactElement => {
    return (
        <Popup
            open={open}
            closeOnDocumentClick={true}
            onClose={onClose}
            overlayStyle={{
                position: 'fixed',
                top: '0',
                background: 'rgba(0, 0, 0, 0.50)',
                overflowY: 'scroll'
            }}
            contentStyle={{
                margin: 'auto',
                maxWidth: '1400px',
                minWidth: '300px'
            }}>
            <div>
                <div
                    className={`${width ? width : ''} ${backgroundColor} ${margin} ${padding} ${
                        rounded ? rounded : ''
                    }`}>
                    {children}
                </div>
            </div>
        </Popup>
    );
};

export default PopUp;
