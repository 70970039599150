import { COUNTRY_CODE } from '@components/lib/interfaces/config/graphql/API';

/**
 * Convert country code in nextjs config into country code format
 *
 * @param {string | undefined} value - Country code in next.config.js
 * @returns {COUNTRY_CODE} - Formatted country code.
 */

const formatCountryCodeRsl = (value: string | undefined): COUNTRY_CODE => {
    if (!value) {
        return COUNTRY_CODE.SG;
    }

    const parts = value.split('-');
    if (parts.length < 2 || !parts[1]) {
        return COUNTRY_CODE.SG;
    }

    const country_code = parts[1].toUpperCase() as COUNTRY_CODE;

    if (Object.values(COUNTRY_CODE).includes(country_code as COUNTRY_CODE)) {
        return country_code as COUNTRY_CODE;
    }

    return COUNTRY_CODE.SG;
};

export default formatCountryCodeRsl;
