//library
import { AppState } from '@components/store/store';

/**
 * This selector is used to get current direction state
 *
 * @param {AppState} state - The current state
 * @returns {'continue' | 'back'} - The direction state
 */
const selectedDirection = (state: AppState): 'continue' | 'back' => state.simulator.direction;

export default selectedDirection;
