//type
import { Quote } from '@components/lib/interfaces/model/models/Quote';

//library
import { AppState } from '@components/store/store';

/**
 * This selector is used to get current quote state
 *
 * @param {AppState} state - The current state
 * @returns {Quote} - The quote state
 */
const selectedQuote = (state: AppState): Quote => state.simulator.quote;

export default selectedQuote;
