//type
import { HeaderProps } from '@components/lib/interfaces/template/layout/Header';

//Library
import React, { ReactElement } from 'react';
import Image from 'next/image';
import formatPhoneNumber from '@components/lib/helper/formatPhoneNumber';
import { useTranslation } from 'react-i18next';
import libphonenumber from 'libphonenumber-js';

/**
 * Creation of the Header component
 * @param {HeaderProps} props - The props
 * @returns {ReactElement} - The display header component
 */
const Header: React.FC<HeaderProps> = ({
    lib: {
        external: { Link }
    }
}: HeaderProps): ReactElement => {
    const { t } = useTranslation();

    const email = t('support.email');
    const subject = 'Request For Solar Quote From GetSolar';
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    return (
        <div className="flex justify-between bg-cosmic-latte text-bright-blue w-full pl-4 pr-6 py-4 md:pt-[27px] md:pb-[21px] md:px-10 h-14 md:h-20 ">
            <div className="flex items-center">
                <svg
                    width="149"
                    height="32"
                    viewBox="0 0 149 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path
                            d="M49.2048 15.1946H57.7472V25.3745H54.9176L54.568 23.5803H54.3517C52.9672 24.9565 51.0912 25.6428 48.7185 25.6428C46.0239 25.6428 43.8623 24.7724 42.2373 23.0316C40.6105 21.2908 39.7988 19.0357 39.7988 16.268C39.7988 13.5002 40.6884 11.0215 42.4675 9.15513C44.2465 7.28877 46.5465 6.35645 49.3675 6.35645C51.7021 6.35645 53.5885 6.91893 55.0266 8.04391C56.4648 9.16889 57.318 10.6069 57.5862 12.3563H53.7044C53.4171 11.4997 52.8962 10.8288 52.1417 10.3472C51.3871 9.86556 50.4612 9.62474 49.3657 9.62474C47.5693 9.62474 46.1312 10.2457 45.053 11.4859C43.9748 12.7279 43.4366 14.3208 43.4366 16.268C43.4366 18.1068 43.9489 19.6033 44.9734 20.7541C45.9979 21.9066 47.3997 22.4812 49.1771 22.4812C50.6499 22.4812 51.8578 22.1595 52.8028 21.5162C53.7459 20.8728 54.2184 19.9543 54.2184 18.757V17.9537H49.2048V15.1946Z"
                            fill="#355995"
                        />
                        <path
                            d="M70.5487 21.1408H73.8629C73.5929 22.5341 72.9058 23.6316 71.8017 24.4349C70.6958 25.2382 69.2906 25.6407 67.5842 25.6407C65.5541 25.6407 63.891 25.0112 62.5982 23.752C61.3037 22.4929 60.6582 20.8449 60.6582 18.81C60.6582 16.6667 61.3089 14.9483 62.6121 13.653C63.9152 12.3577 65.5265 11.7109 67.4492 11.7109C69.2456 11.7109 70.7599 12.2923 71.9903 13.4517C73.2208 14.6128 73.8369 16.3278 73.8369 18.595C73.8369 18.755 73.8196 19.087 73.7833 19.5858H64.0814C64.1523 20.568 64.4985 21.3662 65.1198 21.9837C65.7393 22.5995 66.5614 22.9074 67.5859 22.9074C69.185 22.9074 70.1732 22.3174 70.5505 21.1391L70.5487 21.1408ZM67.4492 14.4443C66.551 14.4443 65.7999 14.7126 65.1994 15.2476C64.5971 15.7825 64.2337 16.462 64.1073 17.2825H70.5487C70.5124 16.4431 70.2078 15.7602 69.6332 15.2338C69.0587 14.7074 68.3301 14.4443 67.4509 14.4443H67.4492Z"
                            fill="#355995"
                        />
                        <path
                            d="M82.1103 25.6426C79.3067 25.6426 77.9067 24.3388 77.9067 21.731V14.6853H75.0234V11.9795H75.9407C76.7662 11.9795 77.3563 11.8229 77.7059 11.5116C78.0555 11.1985 78.232 10.6773 78.232 9.94451V8.33789H81.3575V11.9812H84.888V14.687H81.3575V21.2769C81.3575 21.5813 81.3887 21.8394 81.4527 22.0544C81.515 22.2694 81.6898 22.4689 81.9788 22.6564C82.2661 22.8439 82.6607 22.9385 83.1643 22.9385C83.5779 22.9385 84.0175 22.8766 84.4848 22.751V25.3502C83.6229 25.5463 82.832 25.6443 82.1138 25.6443L82.1103 25.6426Z"
                            fill="#355995"
                        />
                        <path
                            d="M100.248 20.2309C100.248 21.9821 99.6232 23.3203 98.3754 24.2492C97.1259 25.1781 95.5684 25.6425 93.6993 25.6425C91.5066 25.6425 89.8054 25.1024 88.5922 24.0222C87.379 22.9419 86.737 21.4815 86.666 19.6427H90.1688C90.2588 20.5887 90.6049 21.3267 91.2072 21.853C91.8094 22.3794 92.6401 22.6426 93.701 22.6426C94.5629 22.6426 95.2724 22.4517 95.8297 22.0663C96.3869 21.6828 96.6656 21.1065 96.6656 20.3393C96.6656 19.8938 96.5721 19.4999 96.3835 19.161C96.1948 18.8221 95.8989 18.5314 95.4939 18.2906C95.089 18.0498 94.7255 17.8675 94.4019 17.7419C94.0783 17.6163 93.6387 17.4735 93.0814 17.3136L92.0033 16.9919C88.8414 16.0458 87.2596 14.305 87.2596 11.7678C87.2596 10.125 87.8619 8.81257 89.0647 7.83036C90.2675 6.84815 91.7956 6.35791 93.6456 6.35791C95.4957 6.35791 97.0861 6.87224 98.2543 7.89745C99.4225 8.92438 100.033 10.2506 100.087 11.8762H96.5842C96.4942 11.109 96.207 10.4966 95.7224 10.0408C95.2378 9.58492 94.5542 9.35786 93.6733 9.35786C92.7924 9.35786 92.1469 9.56428 91.626 9.97367C91.1051 10.3848 90.8437 10.9301 90.8437 11.6078C90.8437 11.9828 90.9286 12.3182 91.0999 12.6124C91.2712 12.9065 91.536 13.1577 91.8942 13.3624C92.2542 13.5671 92.5813 13.7236 92.8772 13.8303C93.1732 13.9369 93.5729 14.0711 94.0766 14.2328L95.0197 14.5269C95.487 14.6697 95.9041 14.8125 96.2727 14.9552C96.6413 15.098 97.0896 15.3268 97.6209 15.6381C98.1505 15.9512 98.5918 16.2987 98.9414 16.6823C99.2909 17.0659 99.5973 17.5664 99.8568 18.1822C100.116 18.7981 100.248 19.481 100.248 20.2309Z"
                            fill="#355995"
                        />
                        <path
                            d="M109.384 25.6427C107.354 25.6427 105.66 24.9856 104.305 23.6731C102.948 22.3606 102.27 20.6869 102.27 18.6503C102.27 16.6136 102.943 14.9502 104.291 13.6549C105.639 12.3597 107.337 11.7129 109.384 11.7129C111.431 11.7129 113.131 12.3597 114.477 13.6549C115.826 14.9502 116.499 16.6153 116.499 18.6503C116.499 20.6852 115.826 22.383 114.477 23.6869C113.129 24.9907 111.431 25.6427 109.384 25.6427ZM109.384 22.6685C110.48 22.6685 111.369 22.2849 112.053 21.516C112.735 20.7489 113.077 19.7924 113.077 18.6503C113.077 17.5081 112.736 16.5826 112.053 15.8241C111.369 15.0655 110.48 14.6853 109.384 14.6853C108.289 14.6853 107.403 15.0655 106.729 15.8241C106.056 16.5826 105.719 17.5253 105.719 18.6503C105.719 19.7752 106.06 20.7489 106.743 21.516C107.425 22.2849 108.306 22.6685 109.384 22.6685Z"
                            fill="#355995"
                        />
                        <path
                            d="M119.006 25.3742V6.35449H122.455V25.3742H119.006Z"
                            fill="#355995"
                        />
                        <path
                            d="M138.005 22.8027H138.329V25.3743H136.873C135.454 25.3743 134.546 24.8394 134.151 23.7677C133.163 25.0183 131.68 25.6427 129.705 25.6427C128.267 25.6427 127.113 25.3073 126.242 24.6381C125.37 23.969 124.936 22.9902 124.936 21.7053C124.936 20.4908 125.344 19.5396 126.163 18.8515C126.979 18.1635 128.054 17.7575 129.383 17.6319L133.587 17.2036V16.6411C133.587 15.9273 133.381 15.382 132.967 15.007C132.554 14.632 132.005 14.4445 131.323 14.4445C129.939 14.4445 129.131 15.1136 128.897 16.4536H125.448C125.591 15.0259 126.183 13.8786 127.227 13.0116C128.269 12.1464 129.634 11.7129 131.323 11.7129C133.012 11.7129 134.387 12.1464 135.447 13.0116C136.507 13.8786 137.038 15.1687 137.038 16.8819V21.7844C137.038 22.4638 137.361 22.8027 138.009 22.8027H138.005ZM133.585 20.2036V19.5602L130.567 19.8819C129.112 20.0419 128.385 20.6044 128.385 21.5694C128.385 22.5344 129.022 23.016 130.299 23.016C131.161 23.016 131.924 22.789 132.59 22.3331C133.255 21.8773 133.587 21.1686 133.587 20.2036H133.585Z"
                            fill="#355995"
                        />
                        <path
                            d="M140.35 25.3743V11.9812H143.475L143.799 13.8837H144.015C144.482 13.1337 145.003 12.585 145.578 12.2358C146.152 11.8883 146.969 11.7129 148.03 11.7129H149.001V15.1412C148.57 15.0706 148.086 15.0345 147.546 15.0345C146.197 15.0345 145.237 15.4319 144.662 16.2266C144.088 17.0213 143.8 17.9897 143.8 19.1336V25.3743H140.351H140.35Z"
                            fill="#355995"
                        />
                        <path
                            d="M14.7578 6.40069L15.2251 6.36284C15.7945 6.3164 16.3967 6.3164 16.9661 6.36284L17.4334 6.40069V0H14.7578V6.40069Z"
                            fill="#FDC821"
                        />
                        <path
                            d="M6.40332 16.8611C6.38082 16.567 6.3687 16.2763 6.3687 15.9959C6.3687 15.7155 6.38082 15.4283 6.40332 15.1358L6.43966 14.6714H0V17.3256H6.43966L6.40332 16.8611Z"
                            fill="#FDC821"
                        />
                        <path
                            d="M25.7537 14.6714L25.7918 15.1358C25.8143 15.4197 25.8264 15.7086 25.8264 15.9959C25.8264 16.2832 25.8143 16.5842 25.7918 16.8594L25.752 17.3256H32.1951V14.6714H25.7537Z"
                            fill="#FDC821"
                        />
                        <path
                            d="M8.51998 22.305L8.21539 21.9507L3.66211 26.4764L5.55022 28.3565L10.1087 23.8308L9.75045 23.5281C9.31087 23.1565 8.89552 22.7454 8.51825 22.305H8.51998Z"
                            fill="#FDC821"
                        />
                        <path
                            d="M23.6747 9.69115L23.9792 10.0472L28.5308 5.51633L26.6392 3.63965L22.0859 8.16537L22.4442 8.46812C22.8855 8.83967 23.2991 9.25251 23.6747 9.69115Z"
                            fill="#FDC821"
                        />
                        <path
                            d="M3.66211 5.51487L8.21885 10.0475L8.52344 9.68968C8.89379 9.25448 9.30741 8.84165 9.75218 8.46665L10.1104 8.16391L5.55195 3.63818L3.66211 5.51315V5.51487Z"
                            fill="#FDC821"
                        />
                        <path
                            d="M23.6731 22.3036C23.3011 22.7388 22.8874 23.1516 22.4444 23.5266L22.0879 23.8294L26.6394 28.3551L28.5327 26.4732L23.9777 21.9458L23.6731 22.3019V22.3036Z"
                            fill="#FDC821"
                        />
                        <path
                            d="M21.7877 16.1744C21.7877 15.6171 21.7064 15.0787 21.5541 14.5695H20.2509V10.8145H17.5892V14.5695H14.5969V10.8145H11.9352V14.5695H10.6321C10.4798 15.0787 10.3984 15.6171 10.3984 16.1744C10.3984 18.8441 12.2589 21.0803 14.7613 21.6772V32.0016H17.423V21.6772C19.9255 21.082 21.786 18.8441 21.786 16.1744H21.7877Z"
                            fill="#355995"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_646_3471">
                            <rect width="149" height="32" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div className="flex items-center">
                <Link href={mailtoLink}>
                    <div className="flex items-center mr-5">
                        <Image
                            src="/email.svg"
                            alt="Email"
                            width={20}
                            height={20}
                            className="mr-2"
                        />
                        <span className="hidden md:inline-block">{email}</span>
                    </div>
                </Link>

                <div className="flex items-center">
                    <a
                        href={libphonenumber(t('support.number'))?.getURI()}
                        className="flex items-center">
                        <Image
                            src="/phone.svg"
                            alt="Contact"
                            width={20}
                            height={20}
                            className="mr-1.5"
                        />
                        <span className="hidden md:inline-block">
                            {formatPhoneNumber(t('support.number'))}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Header;
