//type
import { Mixpanel } from 'mixpanel-browser';
import { Quote } from '@components/lib/interfaces/model/models/Quote';
import { POPUP } from '@components/store/interfaces/slices/simulator/state';
import { BUILDING_TYPE } from '@components/lib/interfaces/config/graphql/API';

//library
import { useEffect } from 'react';
import { NextRouter } from 'next/router';

//code
import useMixpanel from '@components/lib/helper/useMixpanel';

/**
 * A custom hook to send mixpanel signals on simulator store's change
 * @param {Mixpanel} mixpanel mixpanel lib
 * @param {string} stepName step name
 * @param {Quote} quote quote
 * @param {'web' | 'mobile'} set swt
 * @param {'back' | 'continue'} direction direction
 * @param {NextRouter} router router lib
 * @param {Record<POPUP, boolean>} popup popup
 * @returns {void} - Object of mixpanel functions
 */
const useMixpanelEffect = (
    mixpanel: Mixpanel,
    stepName: string | null,
    quote: Quote,
    set: 'desktop' | 'mobile',
    direction: 'back' | 'continue',
    router: NextRouter,
    popup?: Record<POPUP, boolean>
): void => {
    const mixpanelBrowser = useMixpanel(mixpanel);
    const utm = router.asPath.split('?')[1];
    const address = quote.address_components?.reduce(
        (result, value) => {
            result['address_components_' + value.types[0]] = value.long_name;
            return result;
        },
        {} as Record<string, string>
    );

    const listBuildingType = {
        [BUILDING_TYPE.LANDED_HOUSE]: {
            value: 1,
            label: 'Landed house'
        },
        [BUILDING_TYPE.CONDOMINIUM]: {
            value: 2,
            label: 'Condominium'
        },
        [BUILDING_TYPE.HDB_FLAT]: {
            value: 3,
            label: 'HDB flat'
        },
        [BUILDING_TYPE.RENTED_COMMERCIAL_PROPERTY]: {
            value: 4,
            label: 'Rented commercial property'
        },
        [BUILDING_TYPE.OWNED_COMMERCIAL_PROPERTY]: {
            value: 5,
            label: 'Owned commercial property'
        }
    };

    /* Effect to update mixpanel */
    useEffect(() => {
        if (!stepName) {
            return;
        }

        // Identify the user if contact details have been provided
        if (quote.contact) {
            mixpanelBrowser.identify({
                email: quote.contact.email,
                first_name: quote.contact.name,
                company: quote.contact.company
            });
        }

        // track the steps
        mixpanelBrowser.track(`Step: ${stepName}`, {
            ...quote,
            type: quote.type ? listBuildingType[quote.type] : null,
            type_label: quote.type ? listBuildingType[quote.type].label : null,
            type_value: quote.type ? listBuildingType[quote.type].value : null,
            set,
            utm: utm,
            direction: direction,
            contact_email: quote.contact?.email,
            contact_name: quote.contact?.name,
            contact_company: quote.contact?.company,
            contact_phone: quote.contact?.phone,
            ...address
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepName]);

    useEffect(() => {
        if (!stepName || !popup) {
            return;
        }

        let trackName = ``;
        if (stepName == 'area' && popup && popup.error) {
            trackName = 'map_error';
        }

        if (stepName == 'area' && popup && popup.error && popup.measureVideoTutorial) {
            trackName = 'map_tutorial';
        }

        if (trackName) {
            // track the steps
            mixpanelBrowser.track(trackName, {
                ...quote,
                type: quote.type ? listBuildingType[quote.type] : null,
                type_label: quote.type ? listBuildingType[quote.type].label : null,
                type_value: quote.type ? listBuildingType[quote.type].value : null,
                set,
                utm: utm,
                direction: direction,
                contact_email: quote.contact?.email,
                contact_name: quote.contact?.name,
                contact_company: quote.contact?.company,
                contact_phone: quote.contact?.phone,
                ...address
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popup]);

    return;
};

export default useMixpanelEffect;
