import libphonenumber from 'libphonenumber-js';

// Format a phone number based on the country
/**
 *
 * @param number
 */
function formatPhoneNumber(number: string) {
    const phoneNumber = libphonenumber(number);
    const formatted = phoneNumber?.formatInternational();
    return formatted ?? number;
}

export default formatPhoneNumber;
