//type
import { MixpanelIdentity, MixpanelFunctions } from '@components/lib/interfaces/helper/useMixpanel';

//library
import { useEffect, useRef } from 'react';
import { Mixpanel } from 'mixpanel-browser';

/**
 * A custom hook to track activity and identify the user to his/her email on mixpanel.
 * @param {Mixpanel} mixpanel mixpanel lib
 * @returns {MixpanelFunctions} - Object of mixpanel functions
 */
const useMixpanel = (mixpanel: Mixpanel): MixpanelFunctions => {
    const cache = useRef<boolean>(true);

    useEffect(() => {
        if (cache.current && process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
            mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {}, 'mixpanel');
            cache.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        track: (name, payload = {}) => {
            if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
                mixpanel.track(name, payload);
            }
        },
        identify: (identity: MixpanelIdentity) => {
            if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
                // need to identify first in order for details to be tracked
                mixpanel.identify(mixpanel.get_distinct_id());

                // add the details to the mixpanel profile
                mixpanel.people.set({
                    $first_name: identity.first_name,
                    $email: identity.email,
                    $company: identity.company
                });
            }
        }
    };
};

export default useMixpanel;
